<template>
  <div v-if="!isLoading" v-scroll="onScroll">
    <v-container v-if="category === 'general'">
      <v-row no-gutters justify="center" class="mb-2">
        <v-col class="mb-2" cols="10">
          <v-card>
            <v-img
              @contextmenu.prevent
              :src="
                products.product_details.length
                  ? products.product_details[0].productdetail_image_url
                  : this.default_image
              "
              :lazy-src="
                products.product_details.length
                  ? products.product_details[0].productdetail_image_url
                  : this.default_image
              "
              height="300px"
            >
              <template v-slot:placeholder>
                <v-row align="center" class="fill-height ma-0" justify="center">
                  <v-progress-circular
                    color="grey lighten-5"
                    indeterminate
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-card>
        </v-col>

        <v-col cols="7">
          <v-card>
            <p id="Detail" ref="Detail" class="ml-2 text-h5 font-weight-bold">
              {{ products.product_name }}
            </p>

            <!-- MENU -->
            <v-row class="mx-1" justify="space-around">
              <v-col cols="12" sm="12" md="12">
                <v-chip-group mandatory selected-class="text-primary">
                  <v-chip
                    class="text-body-2"
                    @click="scroll(page)"
                    v-for="page in pages"
                    :key="page"
                  >
                    {{ page }}
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>

            <!-- DISELENGGARAKAN -->
            <div class="my-2">
              <v-banner
                elevation="4"
                lines="two"
                :avatar="products.store.store_image"
                color="error"
                class="my-1 pa-1"
              >
                <v-banner-text>
                  <b>Diselenggarakan Oleh</b> <br />
                  <router-link
                    :to="`/store/${products.store.user_id}/${products.store.store_id}`"
                  >
                    {{ products.store.store_name }}
                  </router-link>
                </v-banner-text>

                <template v-slot:actions>
                  <v-btn append-icon="mdi-menu-down">Follow</v-btn>
                </template>
              </v-banner>
            </div>

            <!-- TANGGAL, WAKTU, DAN LOKASI -->
            <v-container class="pa-0">
              <v-row>
                <v-col cols="6">
                  <v-card min-height="80" elevation="4">
                    <v-card-text>
                      <v-row align="center" no-gutters>
                        <v-col
                          align-self="center"
                          class="text-subtitle-1 text-center"
                          cols="2"
                        >
                          <v-icon
                            size="large"
                            icon="mdi-calendar-blank"
                          ></v-icon>
                        </v-col>

                        <v-col cols="10" class="text-left">
                          <p class="text-subtitle-1 font-weight-bold">
                            Tanggal & Waktu
                          </p>
                          <p
                            v-if="!products.event_start || !products.event_end"
                            class="d-block text-caption"
                          >
                            Tidak ditentukan
                          </p>
                          <p v-else class="d-block text-caption">
                            {{ convertDate(products.event_start) }}

                            -
                            {{ convertDate(products.event_end) }}
                            ||
                            {{ convertTime(products.event_start) }}
                            -
                            {{ convertTime(products.event_end) }}
                          </p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card min-height="80" elevation="4">
                    <v-card-text>
                      <v-row align="center" no-gutters>
                        <v-col
                          align-self="center"
                          class="text-subtitle-1 text-center"
                          cols="2"
                        >
                          <v-icon size="large" icon="mdi-map-marker"></v-icon>
                        </v-col>

                        <v-col cols="10" class="text-left">
                          <p class="text-subtitle-1 font-weight-bold">Lokasi</p>
                          <p class="d-block text-caption">
                            {{
                              products.product_location == null
                                ? "Tidak ada lokasi"
                                : products.product_location.productlocation_name
                            }}
                          </p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>

            <!-- DESKRIPSI & SYARAT&KETENTUAN -->
            <div id="Deskripsi" ref="Deskripsi" class="my-6">
              <v-card elevation="4">
                <v-tabs v-model="tab" color="primary">
                  <v-tab value="one">Deskripsi</v-tab>
                  <v-tab value="two">Syarat & Ketentuan</v-tab>
                </v-tabs>

                <v-card-text>
                  <v-window v-model="tab">
                    <v-window-item value="one">
                      <p class="text-justify" style="white-space: pre-line">
                        {{ products.product_description }}
                      </p>
                    </v-window-item>

                    <v-window-item value="two">
                      <p class="text-justify" style="white-space: pre-line">
                        {{ products.product_toc }}
                      </p>
                    </v-window-item>
                  </v-window>
                </v-card-text>
              </v-card>
            </div>

            <!-- KATALOG -->
            <div id="Katalog Tiket" ref="Katalog Tiket" class="ma-2">
              <p class="mb-1 text-h6 font-weight-bold">Katalog Tiket</p>

              <v-row no-gutters>
                <v-col cols="5">
                  <v-select
                    v-model="selectedType"
                    label="Tipe"
                    :items="types"
                  ></v-select>
                </v-col>

                <v-col v-if="selectedType === 'Semua'" cols="8">
                  <v-card
                    elevation="4"
                    class="mb-2"
                    v-for="type in products.product_types"
                    :key="type.producttype_id"
                  >
                    <v-row no-gutters>
                      <v-col cols="8">
                        <v-card-title class="text-subtitle-1 font-weight-bold">
                          {{ products.product_name }}
                        </v-card-title>
                      </v-col>
                      <v-col cols="4">
                        <v-card-text
                          v-if="type.is_limited == false"
                          class="text-right text-subtitle-1 text-success"
                        >
                          SELALU ADA STOK
                        </v-card-text>
                        <v-card-text
                          v-else-if="type.producttype_qty <= 0"
                          class="text-right text-subtitle-1 text-warning"
                        >
                          HABIS TERJUAL
                        </v-card-text>
                        <v-card-text v-else class="text-right text-subtitle-2"
                          ><i
                            >Sisa:
                            {{ type.producttype_qty.toLocaleString() }} tiket
                          </i>
                        </v-card-text>
                      </v-col>
                    </v-row>
                    <v-card-subtitle class="text-caption">
                      Tipe {{ type.producttype_name }}
                    </v-card-subtitle>
                    <v-divider class="mx-2 my-1"></v-divider>
                    <v-row no-gutters align="center">
                      <v-col cols="6">
                        <v-card-text class="text-h6 font-weight-bold text-left">
                          Rp. {{ type.producttype_price.toLocaleString() }}
                        </v-card-text>
                      </v-col>
                      <v-col
                        cols="6"
                        v-if="
                          type.is_limited == false || type.producttype_qty > 0
                        "
                      >
                        <v-card-text class="text-right">
                          <div
                            v-if="!this.cart.length ? true : checkType(type)"
                          >
                            <v-btn
                              @click="addToCart(type)"
                              class="text-none"
                              color="primary"
                              >Pilih Tiket</v-btn
                            >
                          </div>
                          <div v-else>
                            <v-icon
                              @click="removeFromCart(type.producttype_id)"
                              color="primary"
                              class="mr-2"
                              size="large"
                              >mdi-trash-can</v-icon
                            >
                            <v-btn
                              @click="
                                manageCart(type, false);
                                toOrder();
                              "
                              color="primary"
                              icon="mdi-minus"
                              size="medium"
                              class="mx-2"
                              :disabled="
                                getQty(type.producttype_id) == 0 ? true : false
                              "
                              rounded="lg"
                            >
                            </v-btn>
                            {{ getQty(type.producttype_id) }}
                            <v-btn
                              @click="
                                manageCart(type, true);
                                toOrder();
                              "
                              color="primary"
                              icon="mdi-plus"
                              size="medium"
                              class="mx-2"
                              :disabled="
                                type.producttype_qty < 0
                                  ? true
                                  : type.producttype_qty ===
                                    getQty(type.producttype_id)
                                  ? true
                                  : type.producttype_maximum_order === 0
                                  ? false
                                  : getQty(type.producttype_id) >=
                                    type.producttype_maximum_order
                                  ? true
                                  : false
                              "
                              rounded="lg"
                            >
                            </v-btn>
                          </div>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>

                <v-col v-else cols="8">
                  <v-card
                    elevation="4"
                    class="mb-2"
                    v-for="type in getDataType"
                    :key="type"
                  >
                    <v-row no-gutters>
                      <v-col cols="8">
                        <v-card-title class="text-subtitle-1 font-weight-bold">
                          {{ type.producttype_name }}
                        </v-card-title>
                      </v-col>
                      <v-col cols="4">
                        <v-card-text
                          v-if="type.is_limited == false"
                          class="text-right text-subtitle-1 text-success"
                        >
                          SELALU ADA STOK
                        </v-card-text>
                        <v-card-text
                          v-if="type.producttype_qty <= 0"
                          class="text-right text-subtitle-1 text-warning"
                        >
                          HABIS TERJUAL
                        </v-card-text>
                        <v-card-text v-else class="text-right text-subtitle-2"
                          ><i
                            >Sisa:
                            {{ type.producttype_qty.toLocaleString() }} tiket
                          </i>
                        </v-card-text>
                      </v-col>
                    </v-row>
                    <v-card-subtitle class="text-caption">
                      Tipe {{ type.producttype_name }}
                    </v-card-subtitle>
                    <v-divider class="mx-4 my-1"></v-divider>
                    <v-row no-gutters align="center">
                      <v-col cols="6">
                        <v-card-text class="text-h6 font-weight-bold text-left">
                          Rp. {{ type.producttype_price.toLocaleString() }}
                        </v-card-text>
                      </v-col>
                      <v-col cols="6">
                        <v-card-text
                          class="text-right"
                          v-if="
                            type.is_limited == true && type.producttype_qty > 0
                          "
                        >
                          <div
                            v-if="!this.cart.length ? true : checkType(type)"
                          >
                            <v-btn
                              @click="addToCart(type)"
                              class="text-none"
                              color="primary"
                              >Pilih Tiket</v-btn
                            >
                          </div>
                          <div v-else>
                            <v-icon
                              @click="removeFromCart(type.producttype_id)"
                              color="primary"
                              class="mr-2"
                              size="large"
                              >mdi-trash-can</v-icon
                            >
                            <v-btn
                              @click="
                                manageCart(type, false);
                                toOrder();
                              "
                              color="primary"
                              icon="mdi-minus"
                              size="medium"
                              class="mx-2"
                              :disabled="
                                getQty(type.producttype_id) == 0 ? true : false
                              "
                              rounded="lg"
                            >
                            </v-btn>
                            {{ getQty(type.producttype_id) }}
                            <v-btn
                              @click="
                                manageCart(type, true);
                                toOrder();
                              "
                              color="primary"
                              icon="mdi-plus"
                              size="medium"
                              class="mx-2"
                              :disabled="
                                !type.is_limited
                                  ? false
                                  : type.producttype_qty < 0
                                  ? true
                                  : type.producttype_qty ===
                                    getQty(type.producttype_id)
                                  ? true
                                  : type.producttype_maximum_order === 0
                                  ? false
                                  : getQty(type.producttype_id) >=
                                    type.producttype_maximum_order
                                  ? true
                                  : false
                              "
                              rounded="lg"
                            >
                            </v-btn>
                          </div>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </div>

            <!-- SHARE -->
            <div id="Bagikan" ref="Bagikan" class="ma-2">
              <p class="mb-1 text-h6 font-weight-bold">Bagikan dengan Teman</p>
              <v-icon class="mx-2" icon="mdi-whatsapp" size="large"></v-icon>
              <v-icon class="mx-2" icon="mdi-instagram" size="large"></v-icon>
              <v-icon class="mx-2" icon="mdi-facebook" size="large"></v-icon>
              <v-icon class="mx-2" icon="mdi-twitter" size="large"></v-icon>
              <v-icon class="mx-2" icon="mdi-gmail" size="large"></v-icon>
            </div>
          </v-card>
        </v-col>

        <v-col cols="3">
          <div class="sticky">
            <CartDetail />
          </div>
        </v-col>
      </v-row>

      <v-dialog v-model="dialog" width="400">
        <v-card class="text-center" rounded="lg">
          <v-card-text>
            <span class="font-weight-bold"> Oops, Kamu Belum Login Nih! </span>
            <br />
            Yuk, masuk dulu supaya bisa beli tiket kesukaanmu!
          </v-card-text>
          <v-card-text class="px-0">
            <v-btn
              class="text-none"
              to="/login"
              variant="flat"
              color="primary"
              width="100"
              >Masuk</v-btn
            >
          </v-card-text>
          <v-card-text class="px-0 pt-0">
            <v-btn
              class="text-none"
              color="primary"
              variant="text"
              width="100"
              @click="dialog = false"
              >Nanti Saja</v-btn
            >
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>

  <div v-else>
    <v-container class="pa-0">
      <v-row no-gutters justify="center">
        <v-col class="mb-2" cols="10">
          <free-style-shimmer
            class="mb-2"
            height="300px"
            width="100%"
            border-radius="10px"
            color="#bdbdbd"
          />
        </v-col>

        <v-col cols="7">
          <v-card>
            <free-style-shimmer
              class="mb-4"
              height="50px"
              width="400px"
              border-radius="10px"
              color="#bdbdbd"
            />

            <!-- MENU -->
            <v-row class="mx-1" justify="space-around">
              <v-col cols="12" sm="12" md="12">
                <v-chip-group mandatory selected-class="text-primary">
                  <free-style-shimmer
                    v-for="index in 6"
                    :key="index"
                    class="mr-4 mb-4"
                    height="25px"
                    width="55px"
                    border-radius="10px"
                    color="#bdbdbd"
                  />
                </v-chip-group>
              </v-col>
            </v-row>

            <!-- DISELENGGARAKAN -->
            <div class="my-2">
              <v-banner
                elevation="4"
                lines="two"
                color="error"
                class="my-1 pa-1"
              >
                <v-banner-text>
                  <paragraph-shimmer :lines="2" :random-size="true" />
                </v-banner-text>

                <template v-slot:actions>
                  <v-btn append-icon="mdi-menu-down">Follow</v-btn>
                </template>
              </v-banner>
            </div>

            <!-- TANGGAL, WAKTU, DAN LOKASI -->
            <v-container class="pa-0">
              <v-row>
                <v-col cols="6">
                  <v-card min-height="80" elevation="4">
                    <v-card-text>
                      <v-row align="center" no-gutters>
                        <v-col
                          align-self="center"
                          class="text-subtitle-1 text-center"
                          cols="2"
                        >
                          <v-icon
                            size="large"
                            icon="mdi-calendar-blank"
                          ></v-icon>
                        </v-col>

                        <v-col cols="10" class="text-left">
                          <p class="text-subtitle-1 font-weight-bold">
                            Tanggal & Waktu
                          </p>
                          <paragraph-shimmer :lines="1" :random-size="true" />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card min-height="80" elevation="4">
                    <v-card-text>
                      <v-row align="center" no-gutters>
                        <v-col
                          align-self="center"
                          class="text-subtitle-1 text-center"
                          cols="2"
                        >
                          <v-icon size="large" icon="mdi-map-marker"></v-icon>
                        </v-col>

                        <v-col cols="10" class="text-left">
                          <p class="text-subtitle-1 font-weight-bold">Lokasi</p>
                          <paragraph-shimmer :lines="1" :random-size="true" />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>

            <div id="Deskripsi" ref="Deskripsi" class="my-6">
              <v-card elevation="4">
                <v-tabs v-model="tab" color="primary">
                  <v-tab value="one">Deskripsi</v-tab>
                  <v-tab value="two">Syarat & Ketentuan</v-tab>
                </v-tabs>

                <v-card-text>
                  <v-window v-model="tab">
                    <v-window-item value="one">
                      <paragraph-shimmer :lines="4" :random-size="true" />
                    </v-window-item>

                    <v-window-item value="two">
                      <paragraph-shimmer :lines="4" :random-size="true" />
                    </v-window-item>
                  </v-window>
                </v-card-text>
              </v-card>
            </div>

            <!-- KATALOG -->
            <div id="Katalog Tiket" ref="Katalog Tiket" class="ma-2">
              <p class="mb-1 text-h6 font-weight-bold">Katalog Tiket</p>

              <v-row no-gutters>
                <v-col cols="5">
                  <v-select
                    v-model="selectedType"
                    label="Tipe"
                    :items="types"
                  ></v-select>
                </v-col>

                <v-col cols="8">
                  <v-card
                    elevation="4"
                    class="mb-2"
                    v-for="index in 2"
                    :key="index"
                  >
                    <v-row no-gutters>
                      <v-col cols="8">
                        <paragraph-shimmer :lines="1" :random-size="true" />
                      </v-col>
                      <v-col cols="4">
                        <paragraph-shimmer :lines="1" :random-size="true" />
                      </v-col>
                    </v-row>
                    <paragraph-shimmer
                      class="my-3"
                      :lines="1"
                      :random-size="true"
                    />
                    <paragraph-shimmer
                      class="my-3"
                      :lines="1"
                      :random-size="true"
                    />

                    <v-divider class="mx-4 my-1"></v-divider>

                    <v-row no-gutters align="center">
                      <v-col cols="6">
                        <paragraph-shimmer
                          class="my-3"
                          :lines="1"
                          :random-size="true"
                        />
                      </v-col>
                      <v-col cols="6">
                        <paragraph-shimmer
                          class="my-3"
                          :lines="1"
                          :random-size="true"
                        />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </div>

            <!-- SHARE -->
            <div id="Bagikan" ref="Bagikan" class="ma-2">
              <p class="mb-1 text-h6 font-weight-bold">Bagikan dengan Teman</p>
              <v-row justify="space-around">
                <v-col cols="12" sm="12" md="12">
                  <v-chip-group>
                    <free-style-shimmer
                      v-for="index in 5"
                      :key="index"
                      class="mr-2 mb-4"
                      height="40px"
                      width="40px"
                      border-radius="20px"
                      color="#bdbdbd"
                    />
                  </v-chip-group>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>

        <v-col cols="3">
          <div class="sticky pt-16">
            <CartDetail />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CartDetail from "@/components/CartDetail.vue";
import { mapActions, mapGetters } from "vuex";
import { toRaw } from "vue";
import { ParagraphShimmer, FreeStyleShimmer } from "vue3-shimmer";
import { utcToLocale } from "@/utils/dateFormatter";
import Cookies from "js-cookie";

export default {
  name: "GeneralDetail",

  props: ["products", "isLoading", "category"],

  components: {
    CartDetail,
    ParagraphShimmer,
    FreeStyleShimmer,
  },

  computed: {
    ...mapGetters(["cart"]),
    isLogin() {
      return Cookies.get("auth_token") ? true : false;
    },
    getDataType() {
      let data = [];
      data.push(
        toRaw(
          this.products.product_types.find(
            (product) => product.producttype_name === this.selectedType
          )
        )
      );
      return data;
    },
  },

  async created() {
    this.emptyCart();

    this.cart.filter((cart) => cart.product_id === this.$route.params.id);
  },

  data() {
    return {
      dialog: false,
      offsetTop: 0,
      width: 1000,
      aspectRatio: 16 / 9,
      default_image: null,
      pages: ["Detail", "Deskripsi", "Katalog Tiket", "Bagikan", "Rekomendasi"],
      types: [],
      tab: null,
      selectedType: "Semua",
    };
  },

  methods: {
    getTypes(product_types) {
      this.types.push("Semua");
      for (const i in product_types) {
        this.types.push(product_types[i].producttype_name);
      }
    },

    onScroll(event) {
      this.offsetTop = event.target.scrollingElement.scrollTop;
    },

    scroll(id) {
      this.$nextTick(() => {
        this.$refs[id].scrollIntoView({ behavior: "smooth" });
      });
    },

    ...mapActions([
      "addItemToCart",
      "addQty",
      "reduceQty",
      "emptyCart",
      "removeItemFromCart",
    ]),

    checkType(type) {
      if (this.cart.length) {
        const data = this.cart[0].products[0].product_types.find(
          (types) => types.producttype_id === type.producttype_id
        );
        if (data) {
          return false;
        } else {
          return true;
        }
      }
    },

    addToCart(type) {
      if (this.isLogin) {
        let product_types = [];
        product_types.push({ ...type, qty: 1 });
        let orders = {
          store_id: this.products.store.store_id,
          store_name: this.products.store.store_name,
          store_image: this.products.store.store_image,
          products: [
            {
              product_id: this.products.product_id,
              product_name: this.products.product_name,
              product_location: this.products.product_location,
              product_types: product_types,
              product_image: this.products.product_details.length
                ? this.products.product_details[0].productdetail_image_url
                : this.default_image,
            },
          ],
        };
        this.$store.dispatch("addItemToCart", orders);
      } else {
        this.dialog = true;
      }
    },

    manageCart(type, add) {
      if (add) {
        this.$store.dispatch("addQty", type.producttype_id);
      } else {
        this.$store.dispatch("reduceQty", type.producttype_id);
      }
    },

    removeFromCart(id) {
      this.$store.dispatch("removeItemFromCart", id);
    },

    toOrder() {
      let product_types = [];
      product_types = this.cart
        .filter((cart) => cart.product_id === this.products.product_id)
        .map((types) => ({
          producttype_id: types.producttype_id,
          producttype_name: types.producttype_name,
          producttype_price: types.producttype_price,
          qty: types.qty,
        }));
      let orders = {
        store_id: this.products.store.store_id,
        store_name: this.products.store.store_name,
        store_image: this.products.store.store_image,
        products: [
          {
            product_id: this.products.product_id,
            product_name: this.products.product_name,
            product_location: this.products.product_location,
            product_types: product_types,
            product_image: this.products.product_details.length
              ? this.products.image
              : this.default_image,
          },
        ],
      };
      this.$store.dispatch("addOrders", orders);
    },

    convertDate(isoDate) {
      return utcToLocale(isoDate, "DD-MM-YYYY");
    },
    convertTime(isoDate) {
      return utcToLocale(isoDate, "HH:mm");
    },

    getQty(id) {
      if (this.cart.length) {
        const data = this.cart[0].products[0].product_types.find(
          (types) => types.producttype_id === id
        );
        return data.qty;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style scoped>
* {
  scroll-margin-top: 100px;
}
</style>
