<template>
  <div class="d-flex justify-center">
    <v-sheet width="350" class="bg-color">
      <v-img
        @contextmenu.prevent
        :src="this.NoImage"
        :lazy-src="this.NoImage"
        width="400"
      ></v-img>
      <p class="text-h6 font-weight-bold text-center">
        Maaf, Kami Tidak Menemukan Yang Kamu Cari
      </p>
      <p class="text-body-2 text-center">
        Silahkan ketik kata kunci lainnya di kolom pencarian
      </p>
    </v-sheet>
  </div>
</template>

<script>
import NoProductImage from "@/assets/05_ilustration_no_product.svg";
export default {
  name: "NoProduct",

  data() {
    return {
      NoImage: NoProductImage,
    };
  },
};
</script>